<template>
  <v-dialog
    :value="value"
    max-width="500px"
    @input="updateDialog"
  >
    <v-card>
      <v-card-title class="justify-center">
        <span class="headline">Troca de senha</span>
      </v-card-title>
      <v-card-text>
        <div class="text-center mb-4">
          Para efetuar a troca de senha, entre em contato:
        </div>
        <div class="text-center my-2">
          servicedesk@cassems.com.br
        </div>
        <div class="text-center my-2">
          (67) 3309-5360
        </div>
        <div class="text-center mt-4">
          Ou, use o botão de auto atendimento para alterar a senha.
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions class="justify-center">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              color="secondary"
              outlined
              class="ma-2"
              href="https://sistemas.cassems.com.br/RDWeb/Pages/pt-BR/password.aspx"
              target="_blank"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left>
                mdi-help-circle-outline
              </v-icon>
              Auto atendimento
            </v-btn>
          </template>
          <span>Caso saiba sua senha</span>
        </v-tooltip>

        <v-spacer />
        
        <v-btn
          color="secondary"
          outlined
          class="ma-2"
          @click="closeDialog"
        >
          <v-icon left>
            mdi-close-circle-outline
          </v-icon>
          Entendi
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PasswordResetDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateDialog(value) {
      this.$emit('input', value);
    },
    closeDialog() {
      this.updateDialog(false);
    },
  },
};
</script>

<style scoped>
.v-card {
  padding: 16px; /* Ajusta conforme necessário */
}

</style>