<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="4"
        xl="3"
      >
        <v-card
          class="mx-auto my-5"
          elevation="5"
        >
          <div class="d-flex justify-center">
            <v-img
              :src="logo"
              alt="Logo CASSEMS"
              max-height="250"
              max-width="250"
            />
          </div>
          <v-card-text class="px-10">
            <v-form
              ref="form"
              @keyup.native.enter="submit"
            >
              <v-text-field
                v-model="authentication.login"
                label="Usuário"
                type="email"
                :rules="$rules.required"
              />
              <v-text-field
                v-model="authentication.password"
                label="Senha"
                type="password"
                :rules="$rules.required"
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              color="secondary"
              large
              depressed
              class="px-5"
              @click="submit"
            >
              ENTRAR
            </v-btn>
          </v-card-actions>
          <div
            class="d-flex justify-center my-5 cursor-pointer"
            @click="dialog = true"
          >
            <p class="text-decoration-underline">
              Esqueceu sua senha?
            </p>
          </div>
        </v-card>
        <div class="text-center cursor-pointer my-5 text-caption">
          <a
            href="https://privacy-central.securiti.ai/notice-embed/785956ad-d873-41ee-baa6-d75881257c83"
            target="_blank"
          >POLÍTICA DE PRIVACIDADE</a>
        </div>
      </v-col>
    </v-row>
    <password-reset-dialog v-model="dialog" />
  </v-container>
</template>
<script>
import { login } from '@/services/login-service'
import { mapActions } from 'vuex'
import PasswordResetDialog from '../../components/PasswordResetDialog.vue';


export default {
  components: {
    PasswordResetDialog
  },

  data() {
    return {
      authentication: {
        login: '',
        password: ''
      },
      dialog: false
    }
  },
  computed: {
    logo() {
      return require('../../assets/logo-cassems.png')
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          const data = await login(this.authentication)
          await this.login(data)
          this.$router.push({ name: 'main.home' })
        } catch (e) {
          this.$handleHttpError(e)
        }
      }
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.text-decoration-underline {
  text-decoration: underline;
}
</style>